/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { alpha, buttonClasses } from '@mui/material'
import { grey } from '@mui/material/colors'
import type { ThemeOptions } from '@mui/material/styles'
import { createTheme, darken } from '@mui/material/styles'
import merge from 'lodash/merge'

import { themeOptions, currentPortal, getPrimaryColor } from './commonTheme'

const createLightThemeForDRG = () =>
    createTheme(
        merge(themeOptions(currentPortal('drg')), {
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            variants: [
                                {
                                    props: { variant: 'outlined', color: 'primary' },
                                    style: { color: 'black', borderColor: alpha('#000', 0.25) }
                                }
                            ],
                            [`&:is(a.${buttonClasses.root})`]: { color: 'black' },
                            [`&.${buttonClasses.text}.${buttonClasses.colorPrimary}`]: {
                                color: 'black'
                            },
                            [`&.${buttonClasses.contained}.${buttonClasses.colorSecondary}`]: {
                                color: grey['700'],
                                [`&:is(a.${buttonClasses.root})`]: { color: grey['700'] },
                                backgroundColor: grey['300'],
                                '&:hover': {
                                    backgroundColor: darken(grey['300'], 0.2)
                                }
                            }
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        input: {
                            color: 'black !important',
                            // Cover white fill color set by chrome webkit on autofill fields
                            // by rendering an inner box shadow over the complete field.
                            // E.g. see https://github.com/mui-org/material-ui/issues/283
                            '&:-webkit-autofill': {
                                filter: 'none', // Needed for firefox
                                borderRadius: '0 !important',
                                WebkitBoxShadow: `0 0 0 100px #eee inset !important`,
                                WebkitTextFillColor: 'black !important'
                            }
                        }
                    }
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        input: {
                            color: '#000 !important',
                            '&:focus': {
                                color: '#000'
                            },
                            '&:disabled': {
                                color: 'rgba(0, 0, 0, 0.5) !important'
                            }
                        }
                    }
                }
            },
            palette: {
                mode: 'light',
                primary: { main: getPrimaryColor('drg'), contrastText: '#fff' },
                secondary: {
                    main: '#000'
                },
                background: {
                    default: '#eee',
                    paper: '#fff',
                    card: '#fff'
                },
                footer: {
                    text: 'rgba(0, 0, 0, 0.6)',
                    border: '#d6d6d6',
                    background: '#f1f1f1'
                },
                link: {
                    background: 'rgb(229, 229, 229)'
                },
                button: {
                    background: '#ddd',
                    color: '#666'
                },
                navbar: {
                    backgroundColor: 'transparent'
                },
                invertImage: 'invert(1)'
            }
        } as ThemeOptions)
    )

export default createLightThemeForDRG
